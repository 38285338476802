<!-- @Author: Yu_Bo -->
<template>
	<div class='point_prompt'>
    <el-dialog title="提示" :visible.sync="dialogVisible" :width="width" append-to-body :close-on-click-modal="false">
        <div class="title" slot="title">
            <i class="el-icon-warning"></i>
            <div class="name">温馨提示</div>
        </div>
        <div class="text">{{text}}</div>
        <span slot="footer" class="dialog-footer">
            <el-button size="small" v-if="cancelShow" @click="dialogVisible = false">取 消</el-button>
            <el-button size="small" type="primary" @click="confirm">{{btnText}}</el-button>
        </span>
    </el-dialog>
	</div>
</template>

<script>
	export default {
		components: {},
		props: {
      width: {   //宽度
          type: String,
          default: '380px',
      },
      cancelShow: {   //是否显示取消按钮
          type: Boolean,
          default: false,
      },
      text: {   //内容
          type: String,
          default: '',
      },
      btnText: {   //按钮文字
          type: String,
          default: '确 定',
      },
    },
		data() {
			return{
        dialogVisible:false
			}
		},
		computed: {},
		watch: {},
		created() {},
		mounted() {},
		methods: {
      openDialog() {
          this.dialogVisible = true;
      },
      //
      confirm(){
        this.dialogVisible = false
        this.$emit('confirm')
      },
    },
	}
</script>

<style lang='scss' scoped>
    ::v-deep .el-dialog {
        border-radius: 10px;
    }
    ::v-deep .el-dialog__body {
        padding: 0 20px 10px 50px;
    }

    .title {
        display: flex;
        align-items: center;

        .el-icon-warning {
            font-size: 24px;
            color: #ff7432;
        }

        .name {
            padding-left: 10px;
            font-size: 16px;
            margin-top: -3px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #333333;
        }
    }
    .text {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        padding-top: 8px;
    }
    .point {
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ff7432;
        line-height: 23px;
        padding-top: 8px;
    }
</style>
